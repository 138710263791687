<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style lang="less">
#app, html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
