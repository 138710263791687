<template>
  <div id="bar_wrapper">

  </div>
</template>

<script>
export default {
  name: "PCBar"
}
</script>

<style scoped lang="less">
#bar_wrapper {
  width: 100%;
  height: 50px;
}
</style>