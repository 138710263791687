<template>
  <div id="more_navigation">
    <div class="show_more" @click="clickMore">{{ show_more }}<img :src="more_img" alt=""></div>
    <div class="more_navigation_wrapper" :style="show_navigation">
      <div class="content_switch">
        <ul>
          <li @click="changeBody(index)" v-for="(switch_name, index) in switchList">{{ switch_name }}</li>
        </ul>
      </div>
      <div class="navigation_title">
        <h4>{{ navigationTitle }}</h4>
      </div>
      <div class="navigation_wrapper">
        <ul>
          <li v-for="row in rows">
            <a :href="row.url" target="_blank">
              <img :src="row.iconSrc" @error="row.iconSrc" alt="">
              <div class="info">
                <div class="title"><strong>{{ row.title }}</strong></div>
                <div class="introduction" :title="row.introduction">{{ row.introduction }}</div>
              </div>
            </a>
          </li>
        </ul>
        <div :style="[loading_status ? {'display': 'flex'}:{'display': 'none'}]" class="loading">正在加载...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreNavigation",
  data() {
    return {
      navigationTitle: "动漫",
      switchList: ["动漫", "影视", "音乐", "小说", "漫画", "壁纸", "直播", "工具", "其他"],
      rows: [],
      loading_status: true,
      show_more: '查看更多',
      more_img: '/image/jt1w.png',
      show_navigation: {'display': 'none'}
    }
  },
  methods: {
    clickMore() {
      if (this.show_more === '查看更多') {
        this.show_more = '收起'
        this.more_img = '/image/jt2w.png'
        this.show_navigation = {'display': 'flex'}
      } else {
        this.show_more = '查看更多'
        this.more_img = '/image/jt1w.png'
        this.show_navigation = {'display': 'none'}
      }
    },

    changeBody(index) {
      this.navigationTitle = this.switchList[index]
      this.backTop()
      this.rows = []
      this.loading_status = true
      this.getMoreNavigation(index)
    },

    backTop() {
      document.querySelector('.navigation_wrapper').scrollTop = 0
    },

    getMoreNavigation(index) {
      this.$axios({
        method: 'get',
        url: '/getNav',
        params: {index: index}
      }).then((data) => {
        this.rows = data.data
        this.loading_status = false
      }).catch((error) => {
        console.log(error)
      })
    }
  },

  created() {
    this.getMoreNavigation(0)
  }
}
</script>

<style scoped lang="less">
#more_navigation {
  width: 100%;
  height: calc(100% - 400px);
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  user-select: none;

  .show_more:hover {
    color: #cccccc;
  }

  .show_more {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .more_navigation_wrapper {
    box-sizing: border-box;
    border: #2C2C2CFF solid 2px;
    padding: 8px 8px 10px 8px;
    width: min(1000px, 95%);
    height: 100%;
    background-color: rgba(white, 0.3);
    border-radius: 25px;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #2C2C2CFF;
    }

    .content_switch {
      min-height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      ul {
        height: 100%;
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        padding: 0;
        margin: 0;
        cursor: pointer;
        flex-wrap: wrap;

        li {
          height: 30px;
          width: 100px;
          border-radius: 25px;
          background-color: #3C3C3CFF;
          line-height: 30px;
          text-align: center;
          transition: all 0.5s;
          border: #2C2C2CFF solid 2px;
        }

        li:hover {
          background-color: #2C2C2CFF;
        }

        li:active {
          background-color: #3C3C3CFF;
        }
      }
    }

    .navigation_title {
      * {
        margin: 8px;
      }
    }

    .navigation_wrapper {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;

      ul {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 10px;
        justify-content: space-evenly;

        li {
          box-sizing: border-box;
          padding: 0 10px;
          width: calc((100% - 30px) / 4);
          min-width: 225px;
          height: 70px;
          background-color: rgba(white, 0.4);
          border-radius: 10px;
          transition: all 0.5s;

          a {
            text-decoration: none;
            color: black;
            width: 100%;
            height: 100%;
            display: flex;
            padding: 8px 0;
            box-sizing: border-box;

            img {
              height: 100%;
            }

            .info {
              display: flex;
              flex-direction: column;
              padding: 8px 8px 0;
              width: 150px;
              font-size: 14px;

              .introduction {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
              }
            }
          }
        }

        li:hover {
          background-color: rgba(white, 0.5);
        }
      }

      .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
  }
}

</style>