<template>
  <div id="search_wrapper">
    <div class="search_logo" @click="switch_engine_img">
      <img :src="engine_black_list[engine_num]" alt="">
    </div>
    <div class="search_frame">
      <span id="search_input" :style="search_input_style">
        <input type="text"
               :style="input_style"
               @focus="input_focus"
               @blur="input_blur"
               v-model="input_data" @keyup.enter="search_data(input_data)">
      </span>
      <span id="clear_data" @click.left="input_data=''">
        <img src="/image/X.png" alt="">
      </span>
      <span id="search_submit" @click.left="search_data(input_data)">
        <img src="/image/fdj.png" alt="">
        <span>搜索</span>
      </span>
      <div class="preload" :style="preload_style" @mouseover="preload_show = true" @mouseleave="preload_show = false">
        <div class="table_body" v-for="preload in preload_res" :key="preload.sa"
             @click.left="row_click_search(preload.q)">
          <div class="preload_1">{{ preload.q }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PCSearch",
  data() {
    return {
      preload_show: false,
      input_data: '',
      preload_res: [],
      engine_black_list: [
        "/image/baidu_w.png",
        "/image/google_w.png",
        "/image/bing_w.png"
      ],
      engine_num: 0,
      preload_style: '',
      input_style: '',
      search_input_style: '',
    }
  },
  methods: {
    input_focus() {
      if (this.input_data !== '') {
        this.input_style = {'border-radius': '25px 0 0 0'}
        this.preload_style = {'display': 'block'}
        this.search_input_style = {'border-radius': '25px 25px 25px 0'}
      }
    },

    input_blur() {
      if (!this.preload_show) {
        this.input_style = {'border-radius': '25px 0 0 25px'}
        this.preload_style = {'display': 'none'}
        this.search_input_style = {'border-radius': '25px'}
      }
    },

    switch_engine_img() {
      if (this.engine_num < 2) {
        this.engine_num++;
      } else {
        this.engine_num = 0;
      }
    },

    search_data: function (data) {
      if (data === '') {
        return
      }
      switch (this.engine_num) {
        case 0: {
          window.open('https://www.baidu.com/s?wd=' + data, "_blank");
          break
        }
        case 1: {
          window.open('https://www.google.com/search?q=' + data, "_blank");
          break
        }
        case 2: {
          window.open('https://cn.bing.com/search?q=' + data, "_blank");
          break
        }
      }
      this.preload_show = false
      this.input_blur()
    },

    row_click_search: function (data) {
      this.search_data(data)
    },

    get_preload: function (data) {
      if (data !== "") {
        let param = {
          "prod": "pc",
          "wd": data,
          "csor": 1
        }
        const a = this
        this.$http("https://www.baidu.com/sugrec", param)
            .then(function (res) {
                  a.preload_res = res.g
                  if (a.preload_res !== undefined) {
                    a.input_style = {'border-radius': '25px 0 0 0'}
                    a.preload_style = {'display': 'block'}
                    a.search_input_style = {'border-radius': '25px 25px 25px 0'}
                  } else {
                    a.input_style = {'border-radius': '25px 0 0 25px'}
                    a.preload_style = {'display': 'none'}
                    a.search_input_style = {'border-radius': '25px'}
                  }
                }
            ).catch(function (error) {
          console.log(error)
        })
      } else {
        this.input_style = {'border-radius': '25px 0 0 25px'}
        this.preload_style = {'display': 'none'}
        this.search_input_style = {'border-radius': '25px'}
      }
    },
  },

  watch: {
    input_data: function () {
      this.get_preload(this.input_data)
    }
  },
}
</script>

<style scoped lang="less">
#search_wrapper {
  width: 100%;
  height: 260px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .search_logo {
    width: 100px;
    height: 100px;
    position: relative;
    user-select: none;

    img {
      display: block;
      height: 100%;
    }
  }

  .search_frame {
    width: min(700px, 90%);
    height: 50px;
    border-radius: 25px;
    display: flex;
    margin-top: 40px;
    position: relative;

    span {
      user-select: none;
      cursor: pointer;
    }

    #search_input {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      position: absolute;
      left: 0;

      input {
        border-radius: 25px 0 0 25px;
        width: calc(100% - 100px);
        display: block;
        height: 100%;
        border: #2C2C2CFF solid 2px;
        outline: none;
        padding-block: 0;
        padding-inline: 0;
        padding-left: 25px;
        padding-right: 30px;
        font-size: 20px;
        color: #3C3C3CFF;
        box-sizing: border-box;
        background-color: rgba(white, 0);
      }

      input:focus {
        background-color: white;
      }
    }

    #clear_data {
      display: flex;
      align-items: center;
      width: 30px;
      height: 100%;
      justify-content: center;
      cursor: pointer;
      right: 100px;
      position: absolute;

      img {
        width: 20px;
      }
    }

    #search_submit {
      display: flex;
      align-items: center;
      width: 100px;
      height: 100%;
      border-radius: 0 25px 25px 0;
      background-color: #3C3C3CFF;
      justify-content: space-evenly;
      position: absolute;
      right: 0;
      transition: all 0.5s;

      img {
        width: 25px;
      }
    }

    #search_submit:hover {
      background-color: #2C2C2CFF;
    }

    #search_submit:active {
      background-color: #3C3C3CFF;
    }

    .preload {
      width: calc(100% - 100px);
      height: auto;
      overflow-y: auto;
      border-radius: 0 0 25px 25px;
      background-color: rgba(white, 1);
      top: 50px;
      position: absolute;
      border-left: #2C2C2CFF solid 2px;
      border-right: #2C2C2CFF solid 2px;
      border-bottom: #2C2C2CFF solid 2px;
      box-sizing: border-box;
      display: none;
      z-index: 1000;

      .table_body {
        color: black;
        width: 100%;
        height: 30px;
        padding-left: 25px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
      }

      .table_body:hover {
        background-color: #cccccc;
      }
    }
  }
}
</style>