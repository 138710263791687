<template>
  <div id="home_wrapper" @contextmenu="doNothing">
    <div id="home_bgc"></div>
    <div id="logo">
      <h1><a href="/">简导航</a></h1>
    </div>
    <PCBar/>
    <PCSearch/>
    <MoreNavigation/>
    <div id="body_footer">
      <footer>Copyright © 简超人</footer>
    </div>
  </div>
</template>

<script>
import PCSearch from "@/components/PCSearch"
import PCBar from "@/components/PCBar";
import MoreNavigation from "@/components/MoreNavigation";


export default {
  name: "PCHome",
  components: {PCSearch, PCBar, MoreNavigation},
  methods: {
    doNothing: function () {
      window.event.returnValue = false;
    }
  }
}
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white;
  border: none;
}

#home_wrapper {
  width: 100%;
  height: 100%;
  background-image: url(https://api.vvhan.com/api/wallpaper/acg);
  background-repeat: no-repeat;
  background-size: cover;
}

#home_bgc {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
}

#logo {
  height: 0;

  h1 {
    text-indent: -9999px;
    margin: 0;
    height: 0;

    a {
      display: block;
      background-image: url("/public/image/jcr.png");
      background-size: cover;
      height: 0;
    }
  }
}

#body_footer {
  width: 100%;
  bottom: 10px;
  position: fixed;

  footer {
    width: 100%;
    line-height: 20px;
    color: white;
    text-align: center;
  }
}
</style>