import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import {jsonp} from 'vue-jsonp'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = jsonp

new Vue({
  axios,
  router,
  jsonp,
  render: h => h(App)
}).$mount('#app')
